import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, addChatbotDomainPayloadModel, chatbotThreadDataInterface, chatbotThreadListInterface, createChatbotPayloadModel, getChatbotResponseModel, globalIntegration, globalIntegrationType, globalSlackIntegration, slackChannelData, updateChatbotDomainPayloadModel, updateChatbotPayloadModel } from "../models/axiosModel";
import agent from "../services/api";

const IntegrationContext = React.createContext<IntegrationContextPayload | undefined>(undefined);



export interface IntegrationContextPayload {
    integrationList: globalIntegration[] | null,
    setIntegrationList: React.Dispatch<React.SetStateAction<globalIntegration[] | null>>,
    getIntegrationList: () => void,
    globalIntegrationTypeList: globalIntegrationType[],
    setGlobalIntegrationTypeList: React.Dispatch<React.SetStateAction<globalIntegrationType[]>>,
    getGlobalIntegrationTypeList: any,
    integrationDetails: any,
    setIntegrationDetails: any,

    facebookIntegrationDetails: any,
    setFacebookIntegrationDetails: any
    slackIntegrations: globalSlackIntegration[] | null,
    setSlackIntegrations: React.Dispatch<React.SetStateAction<globalSlackIntegration[] | null>>
    getSlackIntegrations: () => void;
}

const IntegrationProvider = ({ children }: ChildrenProps) => {
    const [globalIntegrationTypeList, setGlobalIntegrationTypeList] = useState<globalIntegrationType[]>([])
    const [integrationList, setIntegrationList] = useState<globalIntegration[] | null>(null);
    const [integrationDetails, setIntegrationDetails] = useState(null)
    const [slackIntegrations, setSlackIntegrations] = useState<null | globalSlackIntegration[]>(null)
    const [facebookIntegrationDetails, setFacebookIntegrationDetails] = useState(null)

    const getIntegrationList = async (): Promise<globalIntegration[] | null> => {
        const response = await agent.getMetaIntegration.get('');
        getSlackIntegrations()
        if (response.status) {
            setIntegrationList(response.data)
        }
        return response;
    }
    const getSlackIntegrations = async () => {
        const slackResponse = await agent.getSlackintegrations.get();
        if (slackResponse.status) {
            setSlackIntegrations(slackResponse.data)
        }
    }
    const getGlobalIntegrationTypeList = async () => {
        debugger;
        const responseMetaIntegration = await agent.getMetaIntegrationType.get();
        const responseIntegration = await agent.getIntegrations.get();
        if (responseMetaIntegration.status && responseIntegration.status) {
            let temp = [...responseMetaIntegration.data]
            let temp1 = [...responseIntegration.data]
            temp1 = temp1.filter((integration) => integration.integration_app_name !== "Calendly")
            setGlobalIntegrationTypeList([...temp, ...temp1])
        } else {
            setGlobalIntegrationTypeList([])
        }
    }
    const payload = {
        integrationList,
        setIntegrationList,
        getIntegrationList,
        globalIntegrationTypeList,
        setGlobalIntegrationTypeList,
        getGlobalIntegrationTypeList,
        integrationDetails,
        setIntegrationDetails,
        facebookIntegrationDetails,
        setFacebookIntegrationDetails,
        slackIntegrations,
        setSlackIntegrations,
        getSlackIntegrations
    };

    return (
        <IntegrationContext.Provider value={payload}>
            {children}
        </IntegrationContext.Provider>
    );
};

export { IntegrationContext, IntegrationProvider };
