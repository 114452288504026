import { Box, Divider, Drawer, Grid, List, ListItem, ListItemText, SwipeableDrawer, Typography } from '@mui/material'
import React, { useState } from 'react'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import ListIcon from '@mui/icons-material/List';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../../assets/styles/componentStyles/templatemessages.module.scss'
import { createWhatsappTemplatePayload } from '../../../models/axiosModel';
import defaultWATemplateImg from "../../../assets/images/messageFormats/defaultWATemplateImage.png";

interface TemplatePreviewInterface {
    metaInformation: createWhatsappTemplatePayload,
    flagCampaign?: boolean
}

const TemplatePreview = (props: TemplatePreviewInterface) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };
    const drawerContent = () => (
        <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            sx={{ width: 'auto', padding: '16px', textAlign: 'center' }}
        >
            <Typography variant="body1">51 results</Typography>
            <Box sx={{ marginTop: '16px', borderRadius: '4px', backgroundColor: '#E0E0E0', height: '6px', width: '40px', margin: '0 auto' }}></Box>
        </Box>
    );
    const messageText = (type: string) => {
        let data, template: string = "";
        if (type.toLowerCase() === "body") {
            data = props.metaInformation.whatsapp_template_data.whatsapp_template_body
            template = data.whatsapp_template_body_value
            // Replacing placeholders with sample values
            data.whatsapp_template_body_variable_data.forEach((variable: any) => {
                const placeholder = variable.whatsapp_template_dynamic_variable_key
                const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
                template = template.replaceAll(placeholder, sampleValue);
            });
        } else if(type.toLowerCase() === "header"){
            data = props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0];
            template = data.whatsapp_template_header_data_value
            // Replacing placeholders with sample values
            data.whatsapp_template_header_variable_data.forEach((variable: any) => {
                const placeholder = variable.whatsapp_template_dynamic_variable_key
                const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
                template = template.replaceAll(placeholder, sampleValue);
            });
        }else{
            data = props.metaInformation.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data[0];
            template = data?.whatsapp_template_footer_data_value || ""
            // Replacing placeholders with sample values
            // data.whatsapp_template_header_variable_data.forEach((variable: any) => {
            //     const placeholder = variable.whatsapp_template_dynamic_variable_key
            //     const sampleValue = variable.whatsapp_template_dynamic_variable_sample_value;
            //     template = template.replaceAll(placeholder, sampleValue);
            // });
        }


        // needed transformation
        template = template.replace(/\*(.*?)\*/g, '<b>$1</b>');  // Bold
        template = template.replace(/_(.*?)_/g, '<i>$1</i>');    // Italic
        template = template.replace(/~(.*?)~/g, '<s>$1</s>');    // Strikethrough
        template = template.replace(/```(.*?)```/g, '<code>$1</code>');  // Monospace
        template = template.replace(/\\n/g, '<br>');

        return template;
    }


    const getSubButtonIcon = (whatsapp_template_button_sub_type_id: number | string) => {
        let subId = Number(whatsapp_template_button_sub_type_id)
        switch (subId) {
            case 3:
                return <OpenInNewOutlinedIcon style={{ display: "flex" }} className={styles.previewSubButton} />;
            case 4:
                return <CallIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case 2:
                return <ReplyIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case 1:
                return <ReplyIcon style={{ display: "flex" }} className={styles.previewSubButton} />
            case null:
                return ""
            default:
                return <ListIcon style={{ display: "flex" }} className={styles.previewSubButton} />
        }

    }
    const getSubButtonText = (subBtnData: any) => {
        let fieldVal = subBtnData.whatsapp_template_button_sub_type_field_data.filter((field: any) => field.field_name == "Button Text")[0]

        switch (fieldVal.field_static_value_flag) {
            case true:
                return fieldVal.field_static_value
            case false:
                return fieldVal.whatsapp_template_button_sub_type_field_value
            default:
                return ""
        }
    }


    const [open, setOpen] = React.useState(false);

    const toggleDrawer1 = (newOpen: any) => () => {
        setOpen(newOpen);
    };

    return (
        <>
            {/* PREVIEW */}
            <Grid item xs={12} id="test" style={{ position: "relative" }}>
                <Grid container alignItems={'center'} direction={"column"} className={props.flagCampaign ? styles.previewCampignMainSecrion : styles.previewMainSection} gap={4}>
                    <Grid item paddingBlock={"10px"} style={{ width: "100%", textAlign: "center" }}>
                        <span className={styles.previewSectionTextStyle}>{props.flagCampaign ? "Preview" : "Preview Section"}</span>
                    </Grid>
                    <Grid item padding={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data && props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value ? "0px" : "0px"} className={styles.previewSectionCardBlock}>
                        <Grid container direction={"column"} flexWrap={"nowrap"}>
                            {props.metaInformation?.whatsapp_template_data?.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() == "image" &&
                                <Grid item>
                                    <img src={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value || defaultWATemplateImg} style={{ width: "100%", maxHeight: "300px", borderRadius: "5px 5px 0px 0px" }} />
                                </Grid>
                            }
                            {props.metaInformation?.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() == "video" &&
                                <Grid item>
                                    <video key={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} style={{ width: "100%", maxHeight: "300px" }} controls autoPlay className='video-tag'>
                                        <source src={props.metaInformation.whatsapp_template_data.whatsapp_template_header.whatsapp_template_header_data[0].whatsapp_template_header_data_value} type="video/mp4" />
                                    </video>
                                </Grid>
                            }
                            {props.metaInformation?.whatsapp_template_data.whatsapp_template_header?.whatsapp_template_header_type_name?.toLowerCase() == "text" &&
                                <Grid item sx={{padding: "14px 10px 5px 10px"}}>
                                    <pre
                                        className={styles.preTextStyle}
                                        dangerouslySetInnerHTML={{ __html: messageText("header") }}
                                    />
                                </Grid>
                            }
                            <Grid item sx={{padding: "14px 10px"}}>
                                <pre
                                    className={styles.preTextStyle}
                                    dangerouslySetInnerHTML={{ __html: messageText("body") }}
                                />
                            </Grid> 
                            {props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer && props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data?.[0]?.whatsapp_template_footer_data_value && props.metaInformation?.whatsapp_template_data?.whatsapp_template_footer?.whatsapp_template_footer_data?.[0].whatsapp_template_footer_data_value?.length > 0 && 
                                 <Grid item sx={{padding: "5px 10px 14px 10px"}}>
                                    <pre
                                        className={styles.preTextStyle}
                                        dangerouslySetInnerHTML={{ __html: messageText("footer") }}
                                    />
                             </Grid> 
                            }
                            {
                                props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).length <= 3 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).map((subBtnData: any) => {
                                    return <>
                                        <Grid item paddingBlock={"12px"} style={{ borderTop: "1px solid #CFCFCF", color: "#6A097D", cursor: "pointer" }}>
                                            <Grid container justifyContent={"center"} gap={1} alignItems={"center"}>
                                                <Grid item>
                                                    {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                                </Grid>
                                                <Grid item>
                                                    {getSubButtonText(subBtnData)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                })
                            }
                            {
                                props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).length > 3 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).splice(0, 2).map((subBtnData: any) => {
                                    return <>
                                        <Grid item paddingBlock={"12px"} style={{ borderTop: "1px solid #CFCFCF", color: "#6A097D", cursor: "pointer" }}>
                                            <Grid container justifyContent={"center"} gap={1} alignItems={"center"}>
                                                <Grid item>
                                                    {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                                </Grid>
                                                <Grid item>
                                                    {getSubButtonText(subBtnData)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                })
                            }
                            {props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).length > 3 &&
                                <Grid item paddingBlock={"12px"} style={{ borderTop: "1px solid #CFCFCF", color: "#6A097D", cursor: "pointer" }}
                                    // onClick={() => setDrawerOpen(true)}
                                    onClick={toggleDrawer1(true)}
                                >
                                    <Grid container justifyContent={"center"} gap={1} alignItems={"center"}>
                                        <Grid item>
                                            <MenuIcon style={{ display: "flex" }} />
                                        </Grid>
                                        <Grid item>
                                            See all options
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item style={{ position: "absolute", bottom: "0", backgroundColor: "white", width: "100%", borderRadius: "12px 12px 0 0", boxShadow: "0px 0px 3px rgba(0,0,0,0.2)", padding: "15px", display: openDrawer ? "block" : "none" }} xs={12}>
                        <CloseIcon style={{ position: "absolute", right: "10px", cursor: "pointer" }} onClick={() => setOpenDrawer(false)} />
                        <Grid container direction={"column"} gap={"12px"}>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <span style={{ fontWeight: "500", fontSize: "18px" }}>All Options</span>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Box sx={{ width: "40px", height: "4px", backgroundColor: "gray", borderRadius: "5px" }}></Box>
                            </Grid>
                        </Grid>
                        {
                            props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).map((subBtnData: any) => {
                                return <>
                                    <Grid item paddingBlock={"12px"} style={{ color: "#6A097D", cursor: "pointer" }}>
                                        <Grid container justifyContent={"start"} gap={2} alignItems={"center"}>
                                            <Grid item>
                                                {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                            </Grid>
                                            <Grid item>
                                                {getSubButtonText(subBtnData)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            })
                        }
                    </Grid>
                    {/* <SwipeableDrawer
                            anchor="bottom"
                            open={drawerOpen}
                            onOpen={toggleDrawer(true)}
                            onClose={toggleDrawer(false)}
                            PaperProps={{
                            sx: { borderRadius: '8px 8px 0 0', width: "38.7vw", position: "absolute", left: "59.5vw"},
                            }}
                        >
                            {
                                props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).map((subBtnData: any) => {
                                    return <>
                                        <Grid item paddingBlock={"12px"} style={{ color: "#6A097D", cursor: "pointer"}}>
                                            <Grid container justifyContent={"start"} gap={2} alignItems={"center"}>
                                                <Grid item>
                                                    {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                                </Grid>
                                                <Grid item>
                                                    {getSubButtonText(subBtnData)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                })
                            }
                        </SwipeableDrawer> */}

                    <Drawer
                        open={open}
                        anchor='bottom'
                        onClose={toggleDrawer1(false)}
                        variant="temporary"
                        ModalProps={{
                            container: document.getElementById('test'),
                            disableEnforceFocus: true,
                            style: { position: 'absolute' },
                            keepMounted: false,
                        }}
                        sx={{
                            transition: open ? 'opacity 275ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' : 'opacity 275ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        }}
                        PaperProps={{ style: { position: "absolute", width: "100%" } }}
                        BackdropProps={{ style: { position: "absolute" } }}
                        SlideProps={{
                            direction: open ? "up" : "down",
                            timeout: { enter: 0, exit: 0 },
                            style: {
                                borderRadius: "32px 32px 0px 0px",
                                border: "2px solid #FAF0F0",
                                padding: "15px",
                                transition: open ? 'all 275ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' : 'all 275ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                            }
                        }}
                    >
                        <Grid container direction={"column"} gap={"18px"} marginBottom={"15px"}>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Box sx={{ width: "40px", height: "4px", backgroundColor: "gray", borderRadius: "5px" }}></Box>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <CloseIcon style={{ position: "absolute", left: "15px", cursor: "pointer", color: "#909090" }} />
                                <span style={{ fontWeight: "500", fontSize: "18px" }}>All Options</span>
                            </Grid>
                        </Grid>
                        {
                            props.metaInformation.whatsapp_template_data.whatsapp_template_button.length > 0 && props.metaInformation.whatsapp_template_data.whatsapp_template_button.flatMap((btn: any) => btn.whatsapp_template_button_data).map((subBtnData: any) => {
                                return <Grid container>
                                    <Grid item paddingBlock={"10px"} style={{ color: "#6A097D", cursor: "pointer" }}>
                                        <Grid container justifyContent={"start"} gap={2} alignItems={"center"}>
                                            <Grid item>
                                                {getSubButtonIcon(subBtnData.whatsapp_template_button_sub_type_id)}
                                            </Grid>
                                            <Grid item>
                                                {getSubButtonText(subBtnData)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            })
                        }
                    </Drawer>

                </Grid>
            </Grid>
        </>
    )
}

export default TemplatePreview
