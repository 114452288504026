import axios, { AxiosError } from 'axios';
import { LoginPayloadModel, RegisterPayloadModel, SendLoginOTPPayloadModel, VerifyAccountOTPPayloadModel, CreateDocPayloadModel, updateDocumentFormRequestPayloadModel, UpdateDocumentInfoRequestPayloadModel, ExecutePromptRequestPayloadModel, UpdateDocumentOutlineDataPayloadModel, DeleteDocumentOutlineDataParamsModel, UpdateDocumentTitlePayloadModel, UpdateDocumentEditorPayloadModel, CustomerDetailModel, CreatePersonaPayloadModel, createCustomTemplatePayloadModel, UpdatePersonaPayloadModel, updateCustomerSubscriptionPayloadModel, createChatbotPayloadModel, updateChatbotPayloadModel, addChatbotDomainPayloadModel, updateChatbotDomainPayloadModel, createCampaignPayloadModel, updateCampaignDetailsModel, generateCampaignDocumentPayloadModel, markDefaultPersonaPayloadModel, createUserPopupActionPayload, createChatbotIntegrationPayload, manageChatbotConnectorPayload, updateChatbotFlagInternalPayload, createMetaIntegrationPayload, addChatbotIntegrationPayload, updateTimezonePayload, shareOrganizationInvitationPayload, addChatbotTryNumberPayloadModel, chatbotPersistentPayload, calendlyAuthURLPayloadModel, createAgentsDataPayloadModel, updateCallRecordsPayload, postChatbotCalendlyEventTypeDataPayloadModel, additionalFieldFilters, createLeadGenerationPayloadModel, updateLeadGenerationPayloadModel, manageLeadGenerationPayloadModel, createStaticMessageFormatPayload, reGenerateCallRecord, uploadCartItemsPayload, updateFeedbackPayload, importCallRecordPayload, createCampaignContactListPayload, updateCampaignContactListPayload, updateCampaignContactPayload, importCampaignContactDataPayload, getCampaignContactPayload, deleteContactPayload, createCampaignContactTagPayload, updateCampaignContactTagPayload, createCampaignPayload, createCampaignContactFieldPayload, updateCampaignContactFieldPyload, addFeedbackPayload, callAnalysisFeedbackPayload, updateCallAnalysisFeedbackPayload, runCampaignPayload, updateCampaignPayload, createWhatsappTemplatePayloadNew } from '../models/axiosModel';
import { getTime } from 'date-fns';
import { request } from 'http';


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";
const responseBody = (response: any) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err: AxiosError) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        console.log("response.response.data.error--", err?.response?.data)
    }
    return err;
}

const requests = {
    get: async (url: string) => {
        try {

            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            const response = await axios.get(url,
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    signal: controller.signal
                });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        } catch (err: any) {
            return Error(err);
        }
    },
    fileUploadPost: async (url: string, body: any) => {
        try {
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },
    post: async (url: string, body: any, onAbort?: () => void) => {
        try {
            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            // @ts-ignore
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                },
                signal: controller.signal
            });
            return responseBody(response);
        } catch (err: any) {
            if (axios.isCancel(err)) {
                console.log("Request was canceled", err);
                // @ts-ignore
                apiBuffer = apiBuffer.filter((api: any) => api.name !== url)
                if (onAbort) console.log("onabort called");
            } else {
                console.log("not aborted", err)
            }
            return Error(err);
        }
    },
    put: async (url: string, body: any) => {
        try {
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },
    delete: async (url: string) => {
        try {
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    }
};
const UserMeAPI = {
    get: () => requests.get("/api/user/get-user-detail"),
    getURL: "/api/user/get-user-detail"
}

const LoaderDetailsAPI = {
    get: () => requests.get(`api/popup/get-image-groups`)
}

const RegisterAPI = {
    post: (payload: RegisterPayloadModel) => requests.post(`/api/user/create-user`, payload)
}
const SendAccountOTPAPI = {
    post: () => requests.post(`/api/user/account-authentication-code`, {})
}
const VerifyAccountOTPAPI = {
    put: (payload: VerifyAccountOTPPayloadModel) => requests.put("api/user/account-authentication-code", payload)
}
const SendLoginOTPAPI = {
    post: (payload: SendLoginOTPPayloadModel) => requests.post("api/user/auth", payload)
}
const VerifyLoginOTPAPI = {
    put: (payload: LoginPayloadModel) => requests.put("api/user/auth", payload)
}
const LogoutAPI = {
    post: () => requests.post("api/user/logout-user", {})
}
const getTemplateList = {
    get: () => requests.get("api/template/get-template-list")
}
const getTemplateDetail = {
    get: (template_id: number) => requests.get(`api/template/template-detail?template_id=${template_id}`)
}
const createDoc = {
    post: (payload: CreateDocPayloadModel) => requests.post("api/document/create-document", payload)
}
const getDocDetails = {
    get: (document_uuid: String) => requests.get(`api/document/get-document-detail?document_uuid=${document_uuid}`)
}
const getDocList = {
    get: () => requests.get("api/document/get-document-list")
}
const updateDocumentFormSubmissionData = {
    put: (payload: updateDocumentFormRequestPayloadModel) => requests.put("api/document/update-document-form-detail", payload)
}
const updateDocumentInfo = {
    put: (payload: UpdateDocumentInfoRequestPayloadModel) => requests.put("api/document/update-document-info", payload)
}
const getPrompList = {
    get: () => requests.get("api/ai/get-prompt-list")
}
const executePrompt = {
    post: (payload: ExecutePromptRequestPayloadModel, currentTime?: any) => requests.post("api/ai/ask-ai", payload, currentTime),
    getURL: "api/ai/ask-ai"

}
const getDocumentContentTypeData = {
    get: () => requests.get("api/document/get-document-content-type-data")
}

const updateDocumentOutlineData = {
    put: (payload: UpdateDocumentOutlineDataPayloadModel[]) => requests.put("api/document/update-document-outline-detail", payload)
}
const deleteDocument = {
    delete: (document_uuid: string) =>
        requests.delete(`api/document/delete-document?document_uuid=${document_uuid}`)
}
const deleteDocumentOutlineData = {
    delete: (params: DeleteDocumentOutlineDataParamsModel) =>
        requests.delete("api/document/delete-document-outline?" +
            Object.keys(params).map(key => `${key}=${encodeURIComponent((params as any)[key])}`).join('&'))
}
const updateDocumentTitle = {
    put: (payload: UpdateDocumentTitlePayloadModel) => requests.put("api/document/update-document-title", payload)
}
const updateDocumentEditorDetail = {
    put: (payload: UpdateDocumentEditorPayloadModel) => requests.put("api/document/update-document-editor-detail", payload)
}
const contWithGoogle = {
    get: (currentUrl: string, gc_integration_type_id: number) => requests.get(`apiIntegration/api/oauth/outbound/auth-url?currentUrl=${currentUrl}&gc_integration_type_id=${gc_integration_type_id}&chatbot_flag=true`)
}
const getCustomerDetail = {
    get: () => requests.get("api/stripe/get-stripe-customer-detail")
}
const updateCustomerDetail = {
    put: (payload: CustomerDetailModel) => requests.put("api/stripe/update-stripe-customer-detail", payload)
}
const getPricingDetail = {
    get: () => requests.get("api/stripe/get-stripe-pricing-detail")

}
const getStripeCustomerSubscriptionDetail = {
    get: () => requests.get("api/stripe/get-stripe-customer-subscription-detail")
}
const getStripeCustomerPortalLink = {
    get: () => requests.get("api/stripe/get-stripe-customer-portal-link")
}
// persona
const getPersonaList = {
    get: () => requests.get("api/persona/get-persona-list")
}
const getSystemPersonaPublicList = {
    get: () => requests.get("api/persona/get-system-persona-list")
}
const getPersonaDetails = {
    get: (persona_id: number) => requests.get(`api/persona/get-persona-deatils?persona_id=${persona_id}`)
}
const createNewPersona = {
    post: (payload: CreatePersonaPayloadModel) => requests.post("api/persona/create-persona", payload)
}
const updatePersona = {
    put: (payload: UpdatePersonaPayloadModel) => requests.put("api/persona/update-persona-details", payload)
}
const markDefaultPersona = {
    put: (payload: markDefaultPersonaPayloadModel) => requests.put("api/persona/set-default-persona", payload)
}
const removeDefaultPersona = {
    delete: (default_persona_type_id: string, persona_id: string) => requests.delete(`api/persona/remove-default-persona?default_persona_type_id=${default_persona_type_id}&persona_id=${persona_id}`)
}
const getDefaultPersonaTypeList = {
    get: () => requests.get("api/persona/get-default-persona-type-list")
}
const deletePersona = {
    delete: (persona_id: string) =>
        requests.delete(`api/persona/delete-persona?persona_id=${persona_id}`)
}
const getPersonaWritingSkillsDetails = {
    get: () => requests.get("api/persona/get-writing-skills-details")
}
const getReadingSkillsDetails = {
    get: () => requests.get("api/persona/get-reading-skills-details")
}
const getSentenceLengthDetails = {
    get: () => requests.get("api/persona/get-sentence-length-details")
}
const getNarrativeTypeDetails = {
    get: () => requests.get("api/persona/get-narrative-type-details")
}
const getCommunicationTypeDetails = {
    get: () => requests.get("api/persona/get-communication-type-details")
}
const oneTapLoginAuthCallback = {
    post: (payload: any) => requests.post("api/user/auth/callback", payload)
}
const getBannerData = {
    get: () => requests.get("api/banner/get-banner")
}
const fileUpload = {
    post: (payload: any) => requests.fileUploadPost("api/storage/upload-file", payload)
}
const createCustomTemplate = {
    post: (payload: createCustomTemplatePayloadModel) => requests.post("api/template/create-custom-template", payload)
}
const updateCustomTemplate = {
    put: (payload: createCustomTemplatePayloadModel) => requests.put("api/template/update-custom-template", payload)
}
const deleteCustomTemplate = {
    delete: (template_id: string) => requests.delete(`api/template/delete-custom-template?template_id=${template_id}`)
}
// Chatbot
const createChatbot = {
    post: (payload: createChatbotPayloadModel) => requests.post("api/chatbot", payload)
}
const getChatbotList = {
    get: () => requests.get("api/chatbot/chatbot-list")
}
const getChatbotData = {
    get: (id: number) => requests.get("api/chatbot?chatbot_id=" + id)
}
const getFileDetail = {
    get: (id: number) => requests.get("api/storage/file?file_id=" + id + "&flag_info=true")
}
const updateChatbotData = {
    put: (payload: updateChatbotPayloadModel) => requests.put("api/chatbot", payload)
}
const addChatbotDomain = {
    post: (payload: addChatbotDomainPayloadModel) => requests.post("api/chatbot/chatbot-domain", payload)
}
const updateChatbotDomain = {
    put: (payload: updateChatbotDomainPayloadModel) => requests.put("api/chatbot/chatbot-domain", payload)
}
const deleteChatbotDomain = {
    delete: (chatbot_domain_id: number) => requests.delete(`api/chatbot/chatbot-domain?chatbot_domain_id=${chatbot_domain_id}`)
}
const cancelStripeSubscription = {
    delete: (gc_stripe_customer_subscription_id: number) => requests.delete(`api/stripe/cancel-stripe-subscription?gc_stripe_customer_subscription_id=${gc_stripe_customer_subscription_id}`)
}
const UpdateStripeSubscription = {
    put: (payload: updateCustomerSubscriptionPayloadModel) => requests.put(`api/stripe/create-change-stripe-plan-customer-subscription`, payload)
}

const removeChatbot = {
    delete: (chatbot_id: any) => requests.delete(`api/chatbot?chatbot_id=${chatbot_id}`)
}


// Campaign
const getCampaignList = { get: () => requests.get("api/campaign/get-campaign-list") }

const createCampaignList = {
    post: (payload: createCampaignPayloadModel) => requests.post(`api/campaign/create-campaign`, payload)
}

const getCampaignDetails = {
    get: (campaign_id: string) => requests.get(`api/campaign/get-campaign-details?campaign_id=${campaign_id}`)
}

const updateCampaignDetails = {
    put: (payload: updateCampaignDetailsModel) => requests.put(`api/campaign/update-campaign-details`, payload)
}

const removeCampaign = {
    delete: (campaign_id: any) => requests.delete(`api/campaign/remove-campaign?campaign_id=${campaign_id}`)
}

const getTemplateCampaignList = { get: () => requests.get("api/campaign/get-campaign-template-list") }

const generateCampaignDocument = {
    post: (payload: generateCampaignDocumentPayloadModel) => requests.post(`api/campaign/generate-campaign-document`, payload)
}

const getCampaignDocuemnts = { get: (campaign_id: string) => requests.get(`api/campaign/get-campaign-document?campaign_id=${campaign_id}`) }

const getRewriteToneGenericList = {
    get: () => requests.get(`api/global/get-rewrite-tone-list`)
}
const getWorkDomainList = {
    get: () => requests.get(`api/global/get-work-domain-list`)
}
const getLanguageList = {
    get: () => requests.get("api/global/languages")
}
const getRouteSourceIds = {
    get: () => requests.get("api/global/get-route-source-list")
}
const getOutputFormatGenericList = {
    get: () => requests.get("api/global/get-output-format-generic-list"),
    getURL: "api/global/get-output-format-generic-list"

}
const getStripeCurrencyTypeGenericList = {
    get: () => requests.get("api/stripe/get-stripe-currency-type-generic-list")
}
const getPromptLogFieldsGenericList = {
    get: () => requests.get("api/dev_portal/prompt-log-field")
}
const getDialectList = {
    get: () => requests.get("api/dev_portal/dialect")
}
const getPopupList = {
    get: () => requests.get("api/popup/get-module-data-list")
}
const createUserPopupAction = {
    post: (payload: createUserPopupActionPayload) => requests.post("/api/popup/change-user-popup-action", payload)
}
const resetChatbotSession = {
    get: () => requests.get("api/chatbot/reset-chat")
}
const removeFile = {
    delete: (file_id: any) => requests.delete(`api/storage/file?file_id=${file_id}`)
}
// meta
const getMetaCredentials = {
    get: () => requests.get("api/chatbot/get-meta-credential")
}
const createChatbotIntegration = {
    post: (payload: createChatbotIntegrationPayload) => requests.post("api/chatbot/integration", payload)
}
const getChatbotIntegrationData = {
    get: (chatbot_id: string, meta_integration_id: string) => requests.get(`api/chatbot/integration?chatbot_id=${chatbot_id}&meta_integration_id=${meta_integration_id}`)
}
const deleteChatbotIntegration = {
    // , meta_integration_id: string
    // &meta_integration_id=${meta_integration_id}
    delete: (chatbot_id: string) => requests.delete(`api/chatbot/integration?chatbot_id=${chatbot_id}`)
}
const getChatbotThreadsList = {
    get: (chatbot_id: string) => requests.get(`api/chatbot/get-chatbot-threads-list?chatbot_id=${chatbot_id}`)
}
const getChatbotThreadDetails = {
    get: (chatbot_id: string, chatbot_user_id: string) => requests.get(`api/chatbot/get-chatbot-thread-details?chatbot_id=${chatbot_id}&chatbot_user_id=${chatbot_user_id}`)
}
const getCountryCode = {
    get: () => requests.get("'https://ipinfo.io?token")
}
const getChatLogs = {
    get: (chatbot_flag_message_feedback: boolean, page_number: Number, records_per_page: Number, chatbot_id?: Number) => requests.get(`api/chatbot/get-user-chats?chatbot_flag_message_feedback=${chatbot_flag_message_feedback}&page_number=${page_number}&records_per_page=${records_per_page}&chatbot_id=${chatbot_id}`)
}
const getAllChatLogs = {
    get: (chatbot_flag_message_feedback: boolean, page_number: Number, records_per_page: Number) => requests.get(`api/chatbot/get-user-chats?chatbot_flag_message_feedback=${chatbot_flag_message_feedback}&page_number=${page_number}&records_per_page=${records_per_page}`)
}
const getChatThreadData = {
    get: (chatbot_user_id: number) => requests.get(`api/chatbot/get-user-chats?chatbot_user_id=${chatbot_user_id}`)
}
const getChatbotDataConnectorList = {
    get: (chatbot_id: string) => requests.get(`api/chatbot/data-connector-list?chatbot_id=${chatbot_id}`)
}
const manageChatbotDataConnector = {
    post: (payload: any) => requests.post('api/chatbot/v2/data-connector', payload)
}
const deleteChatbotDataConnector = {
    delete: (chatbot_data_connector_id: string, chatbot_id: string) => requests.delete(`api/chatbot/data-connector?chatbot_data_connector_id=${chatbot_data_connector_id}&chatbot_id=${chatbot_id}`)
}
const getChatbotDataConnectorDetail = {
    get: (chatbot_data_connector_id: string, chatbot_id: string) => requests.get(`api/chatbot/data-connector-detail?chatbot_data_connector_id=${chatbot_data_connector_id}&chatbot_id=${chatbot_id}`)
}
const getFunctionMethods = {
    get: () => requests.get('api/global/function-methods')
}
const getFunctionParameterTypeData = {
    get: () => requests.get('api/global/function-parameter-type')
}
const updateFlagInternal = {
    put: (payload: updateChatbotFlagInternalPayload) => requests.put('api/dev_portal/update-chatbot-flag-internal', payload)
}
// new integration calls for global integration
const getMetaIntegration = {
    get: (meta_integration_id: string) => requests.get(`api/meta-integration?meta_integration_id=${meta_integration_id}`)
}
const createMetaIntegration = {
    post: (payload: createMetaIntegrationPayload) => requests.post('api/meta-integration', payload)
}
const deleteMetaIntegration = {
    delete: (meta_integration_id: string) => requests.delete(`api/meta-integration?meta_integration_id=${meta_integration_id}`)
}
const getMetaIntegrationType = {
    get: () => requests.get('api/global/meta-integration-type')
}
const createWhatsappTemplate = {
    post: (payload: any) => requests.post('api/meta-integration/create-whatsapp-template', payload)
}
const getWhatsappTemplateList = {
    get: (meta_integration_whatsapp_id: number) => requests.get(`/api/meta-integration/${meta_integration_whatsapp_id}/whatsapp-template/whatsapp_template_id`)
}
const getWhatsappTemplateDetails = {
    get: (meta_integration_whatsapp_id: number, whatsapp_template_id: number) => requests.get(`api/meta-integration/${meta_integration_whatsapp_id}/whatsapp-template/${whatsapp_template_id}`)
}
const updateWhatsappTemplate = {
    put: (payload: any) => requests.put('api/meta-integration/update-whatsapp-template', payload)
}
const whatsappTemplateDynamicVariableType = {
    get: () => requests.get('api/global/whatsapp-template-dynamic-variable-type')
}
const whatsappTemplateHeaderType = {
    get: () => requests.get('api/global/whatsapp-template-header-type')
}
const whatsappTemplateFooterType = {
    get: () => requests.get('api/global/whatsapp-template-footer-type')
}
const createWhatsappTemplateNew = {
    post: (payload: createWhatsappTemplatePayloadNew) => requests.post('api/create-whatsapp-template', payload)
}
const addChatbotIntegration = {
    post: (payload: addChatbotIntegrationPayload) => requests.post(`api/chatbot/integration`, payload)
}
const removeChatbotIntegration = {
    delete: (chatbot_id: string, meta_integration_id: string, meta_integration_type_id: string, phone_number_id: string) => requests.delete(`api/chatbot/integration?chatbot_id=${chatbot_id}&meta_integration_id=${meta_integration_id}&&meta_integration_type_id=${meta_integration_type_id}&phone_number_id=${phone_number_id}`)
}
const getGenericTimezone = {
    get: () => requests.get('api/global/timezone')
}
const updateUserTimezone = {
    put: (payload: updateTimezonePayload) => requests.put(`api/user/update-timezone`, payload)
}
const shareOrganizationInvitation = {
    post: (payload: shareOrganizationInvitationPayload) => requests.post('api/organization/share-invitation', payload)
}
const acceptOrganizationInvitation = {
    get: (organization_invitation_token: string) => requests.get(`api/organization/accept-invitation?organization_invitation_token=${organization_invitation_token}`)
}
const removeUserFromOrganization = {
    delete: (email_address: string) => requests.delete(`api/organization/remove-user?email_address=${email_address}`)
}
const getOrganizationDetails = {
    get: () => requests.get('api/organization/details')
}
const removeDelegateAccess = {
    delete: () => requests.delete(`api/dev_portal/delegate-access`)
}

//test numbers
const whatsappTryNowNumbers = {
    get: () => requests.get('api/whatsapp-try-now/get-whatsapp-try-now-phone-number')
}

const addTryNowPhoneNumbers = {
    post: (payload: addChatbotTryNumberPayloadModel) => requests.post('api/whatsapp-try-now/add-chatbot-try-now-phone-numbers', payload)
}

const getChatbotTryNowPhoneNumbers = {
    get: (chatbot_id: number) => requests.get(`api/whatsapp-try-now/get-chatbot-try-now-phone-numbers?chatbot_id=${chatbot_id}`)
}

const submitFeedback = {
    post: (data: any) => requests.post(`/api/feedback/chatbot-thread?chatbot_token=${data.token}`, data.payload)
}
const getFile = {
    get: (id: number) => requests.get("api/storage/file?file_id=" + id + "&flag_info=false")
}
const createPersistentMenuAPI = {
    post: (payload: any) => requests.post('api/chatbot/persistent-menu', payload)
}
const updatePersistentMenuAPI = {
    put: (payload: any) => requests.put('api/chatbot/persistent-menu', payload)
}
const removePersistentMenuDataAPI = {
    delete: (chatbot_persistent_menu_id: any) => requests.delete(`api/chatbot/persistent-menu?chatbot_persistent_menu_id=${chatbot_persistent_menu_id}`)
}
const getPlaygroundURl = {
    get: (chatbot_user_id: string) => requests.get(`api/global/get-openai-thread?chatbot_user_id=${chatbot_user_id}`)
}
const getCallCategory = {
    get: () => requests.get("api/call-analysis/get-call-category")
}
const getCallComplexity = {
    get: () => requests.get("api/call-analysis/get-call-complexity")
}
const getCallEvaluationParameter = {
    get: () => requests.get("api/call-analysis/get-call-evaluation-parameter")
}
const getCallAgent = {
    get: () => requests.get("api/call-analysis/get-call-agent")
}
const getCallParentCategory = {
    get: () => requests.get("api/call-analysis/get-call-parent-category")
}
const updateCallRecords = {
    put: (payload: updateCallRecordsPayload) => requests.put(`api/call-analysis/update-call-record`, payload)
}
const regenerateCallRecord = {
    put: (payload: reGenerateCallRecord) => requests.put('api/call-analysis/regenerate-call-analysis', payload)
}
const importCallRecord = {
    post: (payload: importCallRecordPayload) => requests.post('api/call-analysis/batch-import', payload)
}
// const getCallRecords = {
//     get: () => requests.get("api/call-analysis/get-call-records")
// }
const getCallRecords = {
    get: (
        start_date?: string | null,
        end_date?: string | null,
        phone_number?: string | null,
        extension?: string | null,
        call_parent_category_id?: number | null,
        call_category_id?: number | null,
        call_complexity_id?: number | null,
        agent_id?: number | null,
        call_record_additional_field?: additionalFieldFilters[] | null,
        flag_alert?: boolean | null,
        flag_dashboard_analysis?: boolean | null,
        flag_score_in_asc?: boolean | null
    ) => {

        const params = new URLSearchParams();

        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);
        if (phone_number) params.append('phone_number', phone_number);
        if (extension) params.append('extension', extension);
        if (call_parent_category_id !== null && call_parent_category_id !== undefined) params.append('call_parent_category_id', call_parent_category_id?.toString());
        if (call_category_id !== null && call_category_id !== undefined) params.append('call_category_id', call_category_id.toString());
        if (call_complexity_id !== null && call_complexity_id !== undefined) params.append('call_complexity_id', call_complexity_id.toString());
        if (agent_id !== null && agent_id !== undefined) params.append('agent_id', agent_id.toString());
        if (call_record_additional_field && call_record_additional_field.length > 0) {
            params.append('call_record_additional_field', JSON.stringify(call_record_additional_field));
        }
        if (flag_alert !== null && flag_alert !== undefined) {
            params.append('flag_alert', flag_alert ? "true" : "false");
        }
        if (flag_dashboard_analysis !== null && flag_dashboard_analysis !== undefined) {
            params.append('flag_dashboard_analysis', flag_dashboard_analysis ? "true" : "false");
        }
        if (flag_score_in_asc !== null && flag_score_in_asc !== undefined) {
            params.append('flag_score_in_asc', flag_score_in_asc ? "true" : "false");
        }

        return requests.get(`api/call-analysis/get-call-records?${params.toString()}`);
    }
}
const getCallEvaluationScoreColor = {
    get: () => requests.get("api/call-analysis/get-call-evaluation-score-and-color")
}

const getPhoneNumbersList = {
    get: () => requests.get("api/call-analysis/get-phone-numbers")
}
const getExtensionsList = {
    get: () => requests.get("api/call-analysis/get-extensions")
}
const getCallRecordDetails = {
    get: (call_record_id: number) => requests.get(`api/call-analysis/get-call-record-detail?call_record_id=${call_record_id}`)
}
const getCallAnalysisAdditionalField = {
    get: () => requests.get("api/call-analysis/get-call-analysis-additional-field")
}
const getCallAnalysisFeedbackType = {
    get: () => requests.get("api/feedback/call-analysis-feedback-type")
}
const getCallAnalysisOption = {
    get: () => requests.get("api/feedback/call-analysis-option")
}
const getCallAnalysisFeedback = {
    get: (
        feedback_status_id?: number | null,
        modification_status_id?: number | null,
        start_date?: string | null,
        end_date?: string | null,
    ) => {
        const params = new URLSearchParams();
        if (feedback_status_id) params.append('feedback_status_id', feedback_status_id.toString());
        if (modification_status_id) params.append('modification_status_id', modification_status_id.toString());
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);

        return requests.get(`api/feedback/call-analysis-feedback?${params.toString()}`);
    }
}
const createCallAnalysisFeedback = {
    post: (payload: callAnalysisFeedbackPayload) => requests.post('api/feedback/call-analysis-feedback', payload)
}
const updateCallAnalysisFeedback = {
    put: (payload: updateCallAnalysisFeedbackPayload) => requests.put('api/feedback/call-analysis-feedback', payload)
}
const createAgentAPI = {
    post: (payload: createAgentsDataPayloadModel) => requests.post('api/call-analysis/create-agent', payload)
}
const resetChatbotThreadByAdmin = {
    get: (chatbot_id: string, chatbot_user_id: string) => requests.get(`api/chatbot/reset-chatbot-user-thread?chatbot_id=${chatbot_id}&chatbot_user_id=${chatbot_user_id}`)
}

const calendlyAuthUrlApi = {
    post: (payload: calendlyAuthURLPayloadModel) => requests.post('api/oauth/get-auth-url', payload)
}

const getCallbackStatus = {
    get: (state: string) => requests.get(`api/oauth/get-callback-status?state=${state}`)
}

const getCalendlyIntegrationData = {
    get: (calendly_integration_id?: string) => {
        const params = new URLSearchParams();
        if (calendly_integration_id) {
            params.append('calendly_integration_id', calendly_integration_id)
        }
        return requests.get(`api/scheduling-tool/calendly?${params.toString()}`);
    }
}

const postChatbotCalendlyEventTypeData = {
    post: (payload: postChatbotCalendlyEventTypeDataPayloadModel) => requests.post('api/chatbot/calendly', payload)
}

const deleteChatbotCalendlyIntegration = {
    delete: (calendly_integration_id: string, chatbot_id: string) => requests.delete(`api/chatbot/calendly?calendly_integration_id=${calendly_integration_id}&chatbot_id=${chatbot_id}`)
}

const getCustomPages = {
    get: () => requests.get('api/pages/get-pages')
}

const getGenericFieldTypeData = {
    get: () => requests.get('api/global/field-type')
}

const getLeadIntegrationTypeData = {
    get: () => requests.get('api/global/lead-integration-type')
}

const createLeadGeneration = {
    post: (payload: createLeadGenerationPayloadModel) => requests.post('api/lead/create', payload)
}

const updateLeadGeneration = {
    put: (payload: updateLeadGenerationPayloadModel) => requests.put('api/lead/update', payload)
}

const getLeadGenerationData = {
    get: (
        chatbot_id: string,
        chatbot_lead_id?: string | null
    ) => {
        const params = new URLSearchParams();
        params.append('chatbot_id', chatbot_id)
        if (chatbot_lead_id) params.append('chatbot_lead_id', chatbot_lead_id);

        return requests.get(`api/lead/view?${params.toString()}`);
    }
}

const deleteLeadGeneration = {
    delete: (chatbot_id: string, chatbot_lead_id: string) => requests.delete(`api/lead/delete?chatbot_id=${chatbot_id}&chatbot_lead_id=${chatbot_lead_id}`)
}

const manageLeadGeneration = {
    post: (payload: manageLeadGenerationPayloadModel) => requests.post('api/lead/integration', payload)
}

const deleteLeadIntegration = {
    delete: (chatbot_id: string, chatbot_lead_id: string, chatbot_lead_integration_id: string) => requests.delete(`api/lead/integration?chatbot_id=${chatbot_id}&chatbot_lead_id=${chatbot_lead_id}&chatbot_lead_integration_id=${chatbot_lead_integration_id}`)
}

const getIntegrations = {
    get: () => requests.get('api/global/get-integration-app')
}
const getSlackintegrations = {
    get: () => requests.get(`api/chatbot/slack`)
}
const createChatbotSlackIntegration = {
    post: (payload: any) => requests.post('api/chatbot/slack', payload)
}
const deleteChatbotSlackIntegration = {
    delete: (chatbot_slack_assignment_id: string) => requests.delete(`api/chatbot/slack-assignment?chatbot_slack_assignment_id=${chatbot_slack_assignment_id}`)
}
const deleteSlackIntegration = {
    delete: (slack_integration_id: number) => requests.delete(`api/chatbot/slack-integration?slack_integration_id=${slack_integration_id}`)
}
const handleToggleManualOverride = {
    put: (chatbot_user_id: any) => requests.put('api/chatbot/switch-chatbot-thread-mode', chatbot_user_id)
}

const getStaticMessageFormat = {
    get: (chatbot_id: any) => requests.get(`api/message-format/static?chatbot_id=${chatbot_id}`)
}
const createStaticMessageFormat = {
    post: (payload: createStaticMessageFormatPayload) => requests.post('api/message-format/static', payload)
}
const updateStaticMessageFormat = {
    put: (payload: createStaticMessageFormatPayload) => requests.put('api/message-format/static', payload)
}
const deleteStaticMessageFormat = {
    delete: (chatbot_id: string, chatbot_static_message_id: string) => requests.delete(`api/message-format/static?chatbot_id=${chatbot_id}&chatbot_static_message_id=${chatbot_static_message_id}`)
}
const getProductListData = {
    get: (chatbot_id: string) => requests.get(`api/ecom/cart-item?chatbot_id=${chatbot_id}`)
}
const getFeedbackProvidedType = {
    get: () => requests.get(`api/global/get-feedback-provided-type`)
}
const getFeedbackLevel = {
    get: () => requests.get(`api/feedback/level`)
}
const getListOfFeedbackOption = {
    get: (chatbot_id: string, chatbot_token: string) => requests.get(`api/feedback/option?chatbot_id=${chatbot_id}&chatbot_token=${chatbot_token}`)
}
const addFeedback = {
    post: (payload: addFeedbackPayload, chatbot_token: string) => requests.post(`api/feedback?chatbot_token=${chatbot_token}`, payload)
}
const getFeedbackStatus = {
    get: () => requests.get(`api/global/get-feedback-status`)
}
const getFeedbackModificationStatus = {
    get: () => requests.get(`api/global/get-modification-status`)
}
const getFeedback = {
    get: (
        feedback_status_id?: number | null,
        modification_status_id?: number | null,
        start_date?: string | null,
        end_date?: string | null,
    ) => {
        const params = new URLSearchParams();
        if (feedback_status_id) params.append('feedback_status_id', feedback_status_id.toString());
        if (modification_status_id) params.append('modification_status_id', modification_status_id.toString());
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);

        return requests.get(`api/feedback?${params.toString()}`);
    }
}

const updateFeedback = {
    put: (payload: updateFeedbackPayload) => requests.put(`api/feedback`, payload)
}
const uploadCartItems = {
    post: (payload: uploadCartItemsPayload) => requests.post(`api/ecom/upload-cart-item`, payload)
}

const getCampaignContactList = {
    get: () => requests.get(`api/contact/list`)
}

const createCampaignContactList = {
    post: (payload: createCampaignContactListPayload) => requests.post(`api/contact/list`, payload)
}

const updateCampaignContactList = {
    put: (payload: updateCampaignContactListPayload) => requests.put(`api/contact/list`, payload)
}

const getCampaignContact = {
    post: (payload: getCampaignContactPayload) => requests.post(`api/contact`, payload)
}

const updateCampaignContact = {
    put: (payload: updateCampaignContactPayload) => requests.put(`api/contact`, payload)
}

const deleteCampaignContact = {
    put: (payload: deleteContactPayload) => requests.put(`api/contact/delete-contact`, payload),
}

const importCampaignContactData = {
    post: (payload: importCampaignContactDataPayload) => requests.post(`api/contact/import-contact-data`, payload)
}

const getListOfCampaignContactField = {
    get: (contact_list_id: number) => requests.get(`api/contact/field?contact_list_id=${contact_list_id}`)
}

const createCampaignContactField = {
    post: (payload: createCampaignContactFieldPayload) => requests.post(`api/contact/field`, payload)
}

const updateCampaignContactField = {
    put: (payload: updateCampaignContactFieldPyload) => requests.put(`api/contact/field`, payload)
}

const deleteCampaignContactField = {
    delete: (contact_field_id: number) => requests.delete(`api/contact/field?contact_field_id=${contact_field_id}`)
}

const getListOfCampaignFieldType = {
    get: () => requests.get(`api/global/contact-field-type`)
}

const getListOfCampaignContactTag = {
    get: (contact_list_id: number) => requests.get(`api/contact/tag?contact_list_id=${contact_list_id}`),
}

const createCampaignContactTag = {
    post: (payload: createCampaignContactTagPayload) => requests.post(`api/contact/tag`, payload),
}

const updateCampaignContactTag = {
    put: (payload: updateCampaignContactTagPayload) => requests.put(`api/contact/tag`, payload),
}

const deleteCampaignContactTag = {
    delete: (contact_tag_id: number) => requests.delete(`api/contact/tag?contact_tag_id=${contact_tag_id}`),
}

const createCampaign = {
    post: (payload: createCampaignPayload) => requests.post(`api/campaign`, payload),
}

const updateCampaign = {
    put: (payload: updateCampaignPayload) => requests.put(`api/campaign`, payload),
}

const getCampaignData = {
    get: (campaign_id?: number | null) => {
        const params = new URLSearchParams();
        if (campaign_id) params.append('campaign_id', campaign_id.toString());
        return requests.get(`api/campaign?${params.toString()}`);
    }
}

const getCampaignMessageType = {
    get: () => requests.get(`api/global/campaign-message-type`),
}

const runCampaign = {
    post: (payload: runCampaignPayload) => requests.post(`api/campaign/run-campaign`, payload),
}

const agent = {
    UserMeAPI,
    RegisterAPI,
    SendAccountOTPAPI,
    VerifyAccountOTPAPI,
    SendLoginOTPAPI,
    VerifyLoginOTPAPI,
    LogoutAPI,
    getTemplateList,
    getTemplateDetail,
    createDoc,
    getDocDetails,
    getDocList,
    updateDocumentFormSubmissionData,
    updateDocumentInfo,
    getPrompList,
    executePrompt,
    getDocumentContentTypeData,
    deleteDocument,
    updateDocumentOutlineData,
    deleteDocumentOutlineData,
    updateDocumentTitle,
    updateDocumentEditorDetail,
    contWithGoogle,
    getCustomerDetail,
    updateCustomerDetail,
    getPricingDetail,
    getStripeCustomerSubscriptionDetail,
    getStripeCustomerPortalLink,
    getPersonaList,
    getPersonaDetails,
    createNewPersona,
    updatePersona,
    getDefaultPersonaTypeList,
    markDefaultPersona,
    removeDefaultPersona,
    deletePersona,
    getPersonaWritingSkillsDetails,
    getReadingSkillsDetails,
    getSentenceLengthDetails,
    getNarrativeTypeDetails,
    getCommunicationTypeDetails,
    oneTapLoginAuthCallback,
    getBannerData,
    fileUpload,
    createCustomTemplate,
    updateCustomTemplate,
    deleteCustomTemplate,
    cancelStripeSubscription,
    UpdateStripeSubscription,
    createChatbot,
    getChatbotData,
    getFileDetail,
    getChatbotList,
    updateChatbotData,
    addChatbotDomain,
    updateChatbotDomain,
    deleteChatbotDomain,
    getCampaignList,
    createCampaignList,
    getCampaignDetails,
    updateCampaignDetails,
    removeCampaign,
    getTemplateCampaignList,
    generateCampaignDocument,
    getCampaignDocuemnts,
    removeChatbot,
    getRewriteToneGenericList,
    getWorkDomainList,
    getLanguageList,
    getRouteSourceIds,
    getOutputFormatGenericList,
    getSystemPersonaPublicList,
    getStripeCurrencyTypeGenericList,
    getPromptLogFieldsGenericList,
    getDialectList,
    getPopupList,
    createUserPopupAction,
    resetChatbotSession,
    removeFile,
    getMetaCredentials,
    createChatbotIntegration,
    getChatbotIntegrationData,
    deleteChatbotIntegration,
    LoaderDetailsAPI,
    getChatbotThreadsList,
    getChatbotThreadDetails,
    getCountryCode,
    getChatLogs,
    getAllChatLogs,
    getChatbotDataConnectorList,
    manageChatbotDataConnector,
    deleteChatbotDataConnector,
    getChatbotDataConnectorDetail,
    getFunctionMethods,
    getFunctionParameterTypeData,
    updateFlagInternal,
    getMetaIntegration,
    createMetaIntegration,
    deleteMetaIntegration,
    getMetaIntegrationType,
    createWhatsappTemplate,
    getWhatsappTemplateList,
    getWhatsappTemplateDetails,
    updateWhatsappTemplate,
    whatsappTemplateDynamicVariableType,
    whatsappTemplateHeaderType,
    whatsappTemplateFooterType,
    createWhatsappTemplateNew,
    addChatbotIntegration,
    removeChatbotIntegration,
    getGenericTimezone,
    updateUserTimezone,
    getChatThreadData,
    shareOrganizationInvitation,
    acceptOrganizationInvitation,
    removeUserFromOrganization,
    getOrganizationDetails,
    removeDelegateAccess,
    whatsappTryNowNumbers,
    addTryNowPhoneNumbers,
    getChatbotTryNowPhoneNumbers,
    submitFeedback,
    getFile,
    createPersistentMenuAPI,
    removePersistentMenuDataAPI,
    updatePersistentMenuAPI,
    getPlaygroundURl,
    getCallCategory,
    getCallComplexity,
    getCallEvaluationParameter,
    getCallAgent,
    getCallParentCategory,
    getCallRecords,
    getCallEvaluationScoreColor,
    getPhoneNumbersList,
    getExtensionsList,
    regenerateCallRecord,
    importCallRecord,
    getCallRecordDetails,
    getCallAnalysisAdditionalField,
    getCallAnalysisFeedbackType,
    getCallAnalysisOption,
    getCallAnalysisFeedback,
    createCallAnalysisFeedback,
    updateCallAnalysisFeedback,
    createAgentAPI,
    resetChatbotThreadByAdmin,
    updateCallRecords,
    calendlyAuthUrlApi,
    getCallbackStatus,
    getCalendlyIntegrationData,
    postChatbotCalendlyEventTypeData,
    deleteChatbotCalendlyIntegration,
    getCustomPages,
    getGenericFieldTypeData,
    getLeadIntegrationTypeData,
    createLeadGeneration,
    updateLeadGeneration,
    getLeadGenerationData,
    deleteLeadGeneration,
    manageLeadGeneration,
    deleteLeadIntegration,
    getIntegrations,
    getSlackintegrations,
    createChatbotSlackIntegration,
    deleteChatbotSlackIntegration,
    deleteSlackIntegration,
    handleToggleManualOverride,
    getStaticMessageFormat,
    createStaticMessageFormat,
    updateStaticMessageFormat,
    deleteStaticMessageFormat,
    getProductListData,
    getFeedbackProvidedType,
    getFeedbackLevel,
    getListOfFeedbackOption,
    addFeedback,
    getFeedbackStatus,
    getFeedbackModificationStatus,
    getFeedback,
    updateFeedback,
    uploadCartItems,
    getCampaignContactList,
    createCampaignContactList,
    updateCampaignContactList,
    getCampaignContact,
    updateCampaignContact,
    deleteCampaignContact,
    importCampaignContactData,
    getListOfCampaignContactField,
    createCampaignContactField,
    updateCampaignContactField,
    deleteCampaignContactField,
    getListOfCampaignFieldType,
    getListOfCampaignContactTag,
    createCampaignContactTag,
    updateCampaignContactTag,
    deleteCampaignContactTag,
    createCampaign,
    getCampaignData,
    getCampaignMessageType,
    runCampaign,
    updateCampaign,
};

export default agent;