import { FormControlLabel, Grid, Radio, RadioGroup, TextField, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import GlobalButton from '../../../utility/globalButton/button'
import Required from '../../../utility/required';
import styles from './../../../../assets/styles/componentStyles/messageFormatForms/staticMessageFormatForm.module.scss';
import { MessageFormatContext, MessageFormatContextPayload } from '../../../../context/messageFormatContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { initialStaticMessageFormatPayload } from '../messageFormatConstants';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import connectorstyle from '../../../../assets/styles/componentStyles/dataconnector.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import IconsColor from '../../../utility/iconsColor';
import { UserContext, UserContextPayload } from '../../../../context/userContext';

const StaticMessageFormatForm = () => {
    const navigate = useNavigate() 
    const largeScreen = useMediaQuery('(max-width : 1600px) and (min-width : 0px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = searchParams.get('chatbot_id')
    const message_format_id = searchParams.get('message_format_id')
    const {setFlagLoading, flagLoading, setSuccess, success, setError, error} = useContext(UserContext) as UserContextPayload;
    const {staticMessageFormatPayload, setStaticMessageFormatPayload, handleStaticMessageFormatPayload, staticMessageFormatList, setTempStaticMessageFormatPayload, tempStaticMessageFormatPayload,
        handleCreateStaticMessageFormat, GetStaticMessageFormatList, handleUpdateStaticMessageFormat} = useContext(MessageFormatContext) as MessageFormatContextPayload
    // const innerHeight = window.innerHeight - 150;
    const initilMessageObjectFormat = {
        id: 0,
        value: "",
        flagValidJSON: true,
        paramsMapping: "",
        flagValidParamJSON: true
    }
    const [messageObject, setMessageObject] = useState<any[]>([initilMessageObjectFormat])
    const [tempMessageObject, setTempMessageObject] = useState<any[]>([])
    const [currentObject, setCurrentObject] = useState(null)
    const [flagViewObject, setFlagViewObject] = useState(false)
    const [flagViewParams, setFlagViewParams] = useState(false)
    const [objectIndex, setObjectIndex] = useState<number>(10000)

    const reFormatParamsMapping = (fieldMapping: any, index: number) => {
        const mappedParams: { [key: string]: string | string[] } = {};
    
        Object.keys(fieldMapping).forEach((key) => {
            const field = fieldMapping[key];
            
            if (key === "cards" && Array.isArray(field)) {
                const fieldsArray = field
                    .filter((item) => item.message_sequence === index)
                    .map((item) => Object.keys(item)[0]);
                if (fieldsArray.length > 0) {
                    mappedParams[key] = fieldsArray;
                }
            } else if (field.message_sequence === index) {
                mappedParams[key] = field.mapping_fields;
            }
        });
    
        return Object.keys(mappedParams).length > 0 ? JSON.stringify(mappedParams) : "";
    };

    useEffect(() => {
        if (message_format_id && staticMessageFormatList) {
            const foundFormat = staticMessageFormatList.find(
                (list) => list.chatbot_static_message_id === Number(message_format_id)
            );
    
            if (foundFormat) {
                setStaticMessageFormatPayload(foundFormat);
                setTempStaticMessageFormatPayload(foundFormat);
    
                const parsedValue = JSON.parse(foundFormat.chatbot_static_message_value);
                const fieldMapping = foundFormat.field_mapping ? JSON.parse(foundFormat.field_mapping) : "";
    
                const formattedMessages = Array.isArray(parsedValue)
                    ? parsedValue.map((item: any, index: number) => {
                        const paramsMapping = reFormatParamsMapping(fieldMapping, index);
                        return {
                            id: index,
                            value: JSON.stringify(item),
                            flagValidJSON: true,
                            paramsMapping: paramsMapping,
                            flagValidParamJSON: true
                        };
                      })
                    : [{
                        id: 0,
                        value: JSON.stringify(parsedValue),
                        flagValidJSON: true,
                        paramsMapping: reFormatParamsMapping(fieldMapping, 0), // Set paramsMapping for single object
                        flagValidParamJSON: true
                      }];
    
                setMessageObject(formattedMessages);
                setTempMessageObject(formattedMessages);
            } else {
                setStaticMessageFormatPayload(initialStaticMessageFormatPayload);
            }
        } else {
            setStaticMessageFormatPayload(initialStaticMessageFormatPayload);
        }
    }, [message_format_id, staticMessageFormatList]);

    const handleOpenMessageFormat = (jsonObj: any, count: number, flagParam?: boolean) => {
        if(flagParam) {
            setFlagViewParams(true)
            setFlagViewObject(false)
        }
        else {
            setFlagViewParams(false)
            setFlagViewObject(true)
        }
        setCurrentObject(jsonObj)
        setObjectIndex(count)
    }

    const handleCloseMessageFormat = () => {
        setFlagViewParams(false)
        setFlagViewObject(false)
        setCurrentObject(null)
        setObjectIndex(10000)
    }

    const handleCancelMessageFormat = () => {
        if(message_format_id) {
            setStaticMessageFormatPayload(tempStaticMessageFormatPayload);
            setMessageObject(tempMessageObject);
        }
        else {
            setStaticMessageFormatPayload(initialStaticMessageFormatPayload);
            setMessageObject([initilMessageObjectFormat])
        }
    }

    const addNewMessageObject = () => {
        const newObject = {
            id: messageObject.length + 1,
            value: "",
            flagValidJSON: true,
            paramsMapping: "",
            flagValidParamJSON: true
        };
        setMessageObject([...messageObject, newObject]);
    };

    const isStaticFormatDiable = () => {
        if(((staticMessageFormatPayload.chatbot_static_message_name && staticMessageFormatPayload.chatbot_static_message_name.length === 0) || (staticMessageFormatPayload.chatbot_static_message_name && staticMessageFormatPayload.chatbot_static_message_name.trim() === "")) || (staticMessageFormatPayload.chatbot_static_message_when_to_use.length === 0 || staticMessageFormatPayload.chatbot_static_message_when_to_use.trim() === "")) {
            return true;
        }
        if(messageObject.filter((obje) => obje.flagValidJSON === false) && messageObject.filter((obje) => obje.flagValidJSON === false).length > 0) {
            return true;
        }
        if(messageObject.filter((obje) => obje.flagValidParamJSON === false) && messageObject.filter((obje) => obje.flagValidParamJSON === false).length > 0) {
            return true;
        }
        if(messageObject.filter((obje) => obje.value.length == 0 || obje.value.trim() === "") && messageObject.filter((obje) => obje.value.length == 0 || obje.value.trim() === "").length > 0) {
            return true;
        }
        // if(message_format_id) {
        //     // if((JSON.stringify(staticMessageFormatPayload) === JSON.stringify(tempStaticMessageFormatPayload)) && (JSON.stringify(messageObject) === JSON.stringify(tempMessageObject))) {
        //     if((JSON.stringify(staticMessageFormatPayload) === JSON.stringify(tempStaticMessageFormatPayload))) {
        //         return true;
        //     }
        //     else {
        //         return false;
        //     }
        // }
    }

    const handleChangeMessageObject = (e: React.ChangeEvent<HTMLTextAreaElement>, index: number, flagParamsMapping?: boolean) => {
        const updatedMessageObject = [...messageObject];
        if(flagParamsMapping) {
            updatedMessageObject[index].paramsMapping = e.target.value;
        }
        else {
            updatedMessageObject[index].value = e.target.value;
        }
        setMessageObject(updatedMessageObject);
    };

    const handleChangeMessageObjectBlur = (str: any, index: number, flagParamsMapping?: boolean) => {
        let replaceStr = str.replace(/\n(?=\s*[{[(])/g, '').replace(/(?<=[}\])])\n/g, '')
        try {
            JSON.parse(replaceStr);
            if(flagParamsMapping) {
                setMessageObject((prevObjects) =>
                    prevObjects.map((obj, idx) =>
                        idx === index ? { ...obj, flagValidParamJSON: true } : obj
                    )
                );
            }
            else {
                setMessageObject((prevObjects) =>
                    prevObjects.map((obj, idx) =>
                        idx === index ? { ...obj, flagValidJSON: true } : obj
                    )
                );
            }
        } catch (e) {
            if(flagParamsMapping) {
                setMessageObject((prevObjects) =>
                    prevObjects.map((obj, idx) =>
                        idx === index ? { ...obj, flagValidParamJSON: str.length > 0 ? false : true } : obj
                    )
                );
            }
            else {
                setMessageObject((prevObjects) =>
                    prevObjects.map((obj, idx) =>
                        idx === index ? { ...obj, flagValidJSON: str.length > 0 ? false : true } : obj
                    )
                );
            }        
            return false;
        }
        return true;
    }

    const deleteNewMessageObject = (index: number) => {
        const updatedMessageObject = [...messageObject];
        updatedMessageObject.splice(index, 1); 
        setMessageObject(updatedMessageObject);
    };

    const formatParamsMapping = (paramsMapping: any, messageId: number) => {
        const parsedParams = JSON.parse(paramsMapping);
        const formattedMapping: any = {};
    
        Object.entries(parsedParams).forEach(([key, value]) => {
            if (key === "cards" && Array.isArray(value)) {
                formattedMapping[key] = value.map((field) => ({
                    [field]: "variant_id",
                    message_sequence: messageId
                }));
            } else {
                formattedMapping[key] = { mapping_fields: value, message_sequence: messageId };
            }
        });
    
        return formattedMapping;
    };

    const handleCreateStaticMessage = () => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const parsedValues = JSON.stringify(
                messageObject.map((data) => {
                    return JSON.parse(data.value);
                })
            );
            const mappedFieldMappings = messageObject.map((data, index) => {
                if (data.paramsMapping) {
                    return formatParamsMapping(data.paramsMapping, index);
                }
                return "";
            });

            const combinedMapping: any = {};
            mappedFieldMappings.forEach((mapping) => {
                Object.entries(mapping).forEach(([key, value]) => {
                    if (key === "cards") {
                        if (!combinedMapping[key]) {
                            combinedMapping[key] = [];
                        }
                        combinedMapping[key] = combinedMapping[key].concat(value);
                    } else {
                        combinedMapping[key] = value;
                    }
                });
            });

            const tempPayload = {
                chatbot_id: staticMessageFormatPayload.chatbot_id,
                chatbot_static_message_name: staticMessageFormatPayload.chatbot_static_message_name,
                chatbot_static_message_value: messageObject.length > 1 ? parsedValues : JSON.stringify(JSON.parse(messageObject[0].value)),
                chatbot_static_message_when_to_use: staticMessageFormatPayload.chatbot_static_message_when_to_use,
                field_mapping: staticMessageFormatPayload.flag_allow_edit ? JSON.stringify(combinedMapping) : null,
                flag_allow_edit: staticMessageFormatPayload.flag_allow_edit,
                message_format_template_id: staticMessageFormatPayload.message_format_template_id
            };
    
            const tempUpdatePayload = {
                ...tempPayload,
                chatbot_static_message_id: tempStaticMessageFormatPayload.chatbot_static_message_id,
            };

            const res = message_format_id
            ? await handleUpdateStaticMessageFormat(tempUpdatePayload)
            : await handleCreateStaticMessageFormat(tempPayload);

            if (res.status) {
                setSuccess({ ...success, flagShowSuccess: true, message: `Static Message Format ${message_format_id ? 'Updated' : 'Created'} Successfully` });
                await GetStaticMessageFormatList(chatbot_id || "");

                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('message_format_id')) {
                    urlParams.delete('message_format_id');
                }
                navigate(`/chatbot/viewchatbot/messageformat?${urlParams.toString()}`);
                setStaticMessageFormatPayload(initialStaticMessageFormatPayload);
            } else {
                setError({ ...error, flagShowError: true, message: res.error.message });
            }

            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        })();
    };

    return (
        <Grid item sx={{width: "100%"}} className={styles.createStaticMessageFormatFormGrid}>
            <Grid container direction={"column"} gap={"10px"}>
                <Grid item>
                    <span className={styles.createFormatTextStyle}>{message_format_id ? tempStaticMessageFormatPayload.chatbot_static_message_name : "Create New Static Message Format"}</span>
                </Grid>

                <Grid item sx={{height: "calc(100vh - 270px)", maxHeight: "calc(100vh - 270px)"}} className={styles.messageFormatFormGrid}>
                    <Grid container direction={{ xs:"column", md: "row" }} flexWrap={"nowrap"} gap={3}>
                        <Grid item sx={{width: "100%"}} xs={12} md={9} lg={6}>
                            <Grid container direction={"column"} gap={"12px"}>

                                <Grid item>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={styles.messageFormatFormFieldLabel}>Static Message Name <Required /></span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={2}>
                                                <Grid item xs={message_format_id ? 10 : 12}>
                                                    <TextField
                                                        className={styles.messageFormatTextFieldStyle}
                                                        value={staticMessageFormatPayload.chatbot_static_message_name ? staticMessageFormatPayload.chatbot_static_message_name : ""}
                                                        placeholder='Enter Message Name'
                                                        onChange={(event) => handleStaticMessageFormatPayload(Number(chatbot_id) || 0, 'chatbot_static_message_name', event.target.value)}
                                                    />
                                                </Grid>
                                                {message_format_id &&
                                                    <Grid item xs={2} className={styles.messageFormatGridStyle}>
                                                        <span className={styles.messageFormatIDTextStyle}>ID: {staticMessageFormatPayload.chatbot_static_message_id}</span>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={styles.messageFormatFormFieldLabel}>When to Use <Required /></span>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                className={styles.messageFormatTextFieldStyle}
                                                value={staticMessageFormatPayload.chatbot_static_message_when_to_use}
                                                placeholder='Select When to Use'
                                                onChange={(event) => handleStaticMessageFormatPayload(Number(chatbot_id) || 0, 'chatbot_static_message_when_to_use', event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"column"} gap={1}>
                                        <Grid item>
                                            <span className={styles.messageFormatFormFieldLabel}>Dynamic Params? <Required /></span>
                                        </Grid>
                                        <Grid item>
                                            <RadioGroup 
                                                name="use-radio-group" 
                                                value={staticMessageFormatPayload.flag_allow_edit ? "Yes" : "No"}
                                                sx={{flexDirection: "row"}} 
                                                className={styles.messageFormatRadioStyle}
                                                onChange={(event) => handleStaticMessageFormatPayload(Number(chatbot_id) || 0, 'flag_allow_edit', event.target.value === "Yes" ? true : false)}
                                            >
                                                <FormControlLabel value="Yes" label="Yes" control={<Radio />} />
                                                <FormControlLabel value="No" label="No" control={<Radio />} />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {messageObject && Array.isArray(messageObject) &&
                                    messageObject.map((messObj, index) => {
                                        return <Grid item key={index} mt={1}>
                                                <Grid container direction={"column"} gap={1}>
                                                    <Grid item>
                                                        <Grid container justifyContent={"space-between"}>
                                                            <Grid item>
                                                                <span className={styles.messageFormatFormFieldLabel}>{`Message ${index > 0 ? index + 1 : ""}`}</span>
                                                            </Grid>
                                                            <Grid item>
                                                                {index == 0 ?
                                                                    <Grid container gap={"6px"} alignItems={"center"} sx={{cursor: "pointer"}}>
                                                                        <Grid item sx={{display: "flex"}} onClick={addNewMessageObject}>
                                                                            <IconsColor iconName="plus" height="14" width="14" fillColor="#6A097D"/>
                                                                        </Grid>
                                                                        <Grid item onClick={addNewMessageObject}>
                                                                            <span className={`${styles.messageFormatIDTextStyle} ${styles.newObjectColor}`}>New Object</span>
                                                                        </Grid>
                                                                        {messageObject && messageObject.length > 1 &&
                                                                            <Grid item sx={{display: "flex"}} marginLeft={1} onClick={() => deleteNewMessageObject(index)}>
                                                                                <IconsColor iconName="DeleteIcon" width="16" height="16" fillColor="#000000" />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                    :
                                                                    <Grid container sx={{cursor: "pointer"}} onClick={() => deleteNewMessageObject(index)}>
                                                                        <Grid item>
                                                                            <IconsColor iconName="DeleteIcon" width="16" height="16" fillColor="#000000" />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        
                                                    </Grid>
                                                    <Grid item className={styles.messageObjectGridStyle} sx={{backgroundColor: objectIndex === index ? "rgba(251, 244, 255, 1)" : "#FFF"}}>
                                                        <Grid container direction={"column"} gap={"15px"}>
                                                            <Grid item>
                                                                <Grid container direction={"column"} gap={1}>
                                                                    <Grid item>
                                                                        <Grid container justifyContent={"space-between"}>
                                                                            <Grid item>
                                                                                <span className={styles.messageFormatFormFieldLabel}>Message Object <Required /></span>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                {(objectIndex != index || flagViewParams) && <OpenInFullIcon className={styles.fullScreenIconStyle} onClick={() => handleOpenMessageFormat(messObj.value, index)}/>}
                                                                                {(objectIndex === index && flagViewObject && !flagViewParams) && <CloseFullscreenIcon className={`${styles.newObjectColor} ${styles.fullScreenIconStyle}`} onClick={() => handleCloseMessageFormat()}/>}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <textarea
                                                                            value={messObj.value}
                                                                            className={`${connectorstyle.text_area_style} ${connectorstyle.minHeight300} ${connectorstyle.dataConnectorDescription}`}
                                                                            onChange={(e) => handleChangeMessageObject(e, index)}
                                                                            onBlur={() => handleChangeMessageObjectBlur(messObj.value, index)}
                                                                        />
                                                                        {!messObj.flagValidJSON && <span className={`${styles.messageFormatIDTextStyle} ${styles.noValidJSONColor}`}>Not Valid JSON</span>}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {staticMessageFormatPayload.flag_allow_edit &&
                                                                <Grid item>
                                                                    <Grid container direction={"column"} gap={1}>
                                                                        <Grid item>
                                                                            <Grid container justifyContent={"space-between"}>
                                                                                <Grid item>
                                                                                    <span className={styles.messageFormatFormFieldLabel}>Params Mapping </span>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    {(objectIndex != index || flagViewObject) && <OpenInFullIcon className={styles.fullScreenIconStyle} onClick={() => handleOpenMessageFormat(messObj.paramsMapping, index, true)}/>}
                                                                                    {(objectIndex === index && flagViewParams) && <CloseFullscreenIcon className={`${styles.newObjectColor} ${styles.fullScreenIconStyle}`} onClick={() => handleCloseMessageFormat()}/>}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <textarea
                                                                                value={messObj.paramsMapping}
                                                                                className={`${connectorstyle.text_area_style} ${connectorstyle.minHeight300} ${connectorstyle.dataConnectorDescription}`}
                                                                                onChange={(e) => handleChangeMessageObject(e, index, true)}
                                                                                onBlur={() => handleChangeMessageObjectBlur(messObj.paramsMapping, index, true)}
                                                                            />
                                                                            {!messObj.flagValidParamJSON && <span className={`${styles.messageFormatIDTextStyle} ${styles.noValidJSONColor}`}>Not Valid JSON</span>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    })
                                }

                            </Grid>
                        </Grid>

                        {(flagViewObject || flagViewParams) &&
                            <Grid item sx={{width: "100%"}} className={styles.viewMessageObjectGridStyle} xs={12} md={6}>
                                <Grid container direction={"column"} gap={"12px"}>
                                    <Grid item>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item>
                                                <span className={styles.messageFormatFormFieldLabel}>Message Object </span>
                                            </Grid>
                                            <Grid item>
                                                <CloseIcon className={styles.closeIconStyle} onClick={() => handleCloseMessageFormat()}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{maxHeight: "calc(100% - 25px)", overflow: "auto"}}>
                                        <code>
                                            {currentObject}
                                        </code>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item sx={{ width: "100%" }} mt={1}>
                    <Grid container direction={"row"} gap={2} justifyContent={"start"}>
                        <Grid item>
                            <GlobalButton buttonText={"Cancel"} className='borderedButtonStyle' sx={{ minWidth: "130px", fontSize: largeScreen ? "13px !important" : ""}} onClick={() => handleCancelMessageFormat()}/>
                        </Grid>
                        <Grid item>
                            <GlobalButton 
                                buttonText={message_format_id ? "Update" : "Create"} 
                                className='primaryButtonStyle' 
                                sx={{ minWidth: "130px" }} 
                                onClick={handleCreateStaticMessage}
                                disabled={isStaticFormatDiable()}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StaticMessageFormatForm
